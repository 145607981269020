import { inject, Injectable, signal } from "@angular/core";
import { Router } from "@angular/router";
import { scheduleTick } from "@app/utils";

/**
 * NOTE: this is meant for sidepanel UI state only!
 */
@Injectable({ providedIn: "root" })
export class NotificationsPanelService {
  #router = inject(Router);
  readonly expanded = signal(false);

  toggleExpanded = (v?: boolean) => {
    const next = v ?? !this.expanded();
    this.expanded.set(next);
  };

  goToNotificationsPage = () => {
    this.#router.navigate(["notifications"]);
    scheduleTick(() => {
      this.toggleExpanded(false);
    });
  };
}
